import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function NotFoundPage() {
    return (
        <Layout>
            <Seo title='404: Not found' />
            <div className='mh-50vh bg-white flex align-center'>
                <div className='ma text-center'>
                    <h1>Page Not Found</h1>
                    <p className='mt20'>It appears the page you've requested does not exsist. Please use menu to try again.</p>
                </div>
            </div>
        </Layout>
    )
}
